import { useContext } from 'react';
import { Link, Navigate, redirect, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ModalContactContext } from '../../context/ModalContactContext';

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  padding: 2rem;
  gap: 2rem;

  @media screen and (max-width: 900px) {
    width: 90%;
    flex-direction: column;
  }
`;

const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 60%;

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

const ContainerImg = styled.div`
  border-radius: 50%;
  border-style: solid;
  background: linear-gradient(270.91deg, #19a67f 24.87%, #e66922 95.26%)
    border-box;
  background-repeat: no-repeat;
  border: 0.5rem solid transparent;
`;

const Img = styled.div`
  background-image: url(${({ img }) => img});
  background-position: center;
  background-size: cover;
  width: 12rem;
  height: 12rem;
  border-radius: 50%;
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 2rem;
  line-height: 130.52%;
  letter-spacing: -0.05em;

  background: linear-gradient(270.91deg, #e66922 24.87%, #19a67f 95.26%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media screen and (max-width: 800px) {
    /* font-size: 2.2rem; */
  }
`;

const Decription = styled.span`
  font-size: 1rem;
  line-height: 130.52%;
  letter-spacing: -0.05em;
  color: #57606a;

  @media screen and (max-width: 800px) {
    font-size: 1.3rem;
  }

  @media screen and (min-width: 1400px) {
    font-size: 1.3rem;
  }
`;

const DecriptionBold = styled.span`
  font-weight: 700;
  font-size: 1rem;
  line-height: 130.52%;
  letter-spacing: -0.05em;
  color: #57606a;

  @media screen and (max-width: 800px) {
    font-size: 1.3rem;
  }

  @media screen and (min-width: 1400px) {
    font-size: 1.3rem;
  }
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  font-size: 1rem;
  line-height: 130.52%;
  letter-spacing: -0.05em;
  color: #161616;
  cursor: pointer;

  @media screen and (max-width: 800px) {
    padding: 1.5rem;
    /* font-size: 1.3rem; */
  }

  @media screen and (min-width: 1400px) {
    font-size: 1.3rem;
    padding: 1.3rem;
  }
`;

export default function QualificationText({
  title,
  description,
  descriptionBold,
  buttonText = '',
  img,
}) {
  // const { setVisible } = useContext(ModalContactContext);
  const navigate = useNavigate();
  function handleButton() {
    // setVisible(false);
    // return <Navigate to="/jobs" />;
    // document.getElementById('#root').scrollTo({ top: 0, left: 0 });
    // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    // window.scrollTo(0, 0);
    // console.log('teste');
    navigate('/jobs');
    // return redirect('/jobs');
  }

  return (
    <Container>
      <ContainerText>
        <Title>{title}</Title>
        <div>
          <Decription>{description}</Decription>
          <DecriptionBold>{descriptionBold}</DecriptionBold>
        </div>
        {buttonText ? (
          // <Navigate to="/jobs">
          <Button onClick={handleButton}>{buttonText}</Button>
        ) : (
          // <Link to="/jobs">Bora</Link>
          // </Navigate>
          <></>
        )}
      </ContainerText>
      <ContainerImg>
        <Img img={img} />
      </ContainerImg>
    </Container>
  );
}
