import React, { useState } from 'react';

export const BlockScrollContext = React.createContext();

export function BlockScrollProvider({ children }) {
  const [block, setBlock] = useState(false);

  return (
    <BlockScrollContext.Provider value={{ block, setBlock }}>
      {children}
    </BlockScrollContext.Provider>
  );
}
