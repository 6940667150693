import styled from 'styled-components';
import palette from '../../../../assets/color-fade.jpg';

export const Container = styled.div`
  display: flex;
  width: 50%;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #000;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    width: 90%;
  }
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 60%;
  gap: 1rem;

  background: #000;
  border-radius: 10px 0 0 10px;

  @media screen and (max-width: 800px) {
    border-radius: 10px 10px 0 0;
    width: 100%;
  }
`;

export const ContainerImg = styled.div`
  background: url(${palette});
  background-position: center;
  background-size: contain;
  border-radius: 0px 10px 10px 0px;
  background-repeat: no-repeat;
  width: 40%;

  @media screen and (max-width: 800px) {
    width: 100%;
    height: 10rem;
    background-size: contain;
    border-radius: 0 0 10px 10px;
    transform: rotate(90deg);
  }
`;
