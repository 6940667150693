import { useEffect, useState } from 'react';
import ColoredTitle from '../../components/ColoredTitle';
import Title from '../../components/Title';
import CardImageJobs from '../../components/CardImageJobs';
import BaseBoard from '../LadingPage/Components/BaseBoard';
import Modal from './components/Modal';
import {
  Container,
  ContainerSubTitle,
  GalleryContainer,
  Image,
} from './styles';

export default function Jobs() {
  const [images, setImages] = useState([]);
  const [imageSelected, setImageSelected] = useState('');
  const [modalVisible, setModalVisible] = useState(false);

  function importAll(r) {
    return r.keys().map(r);
  }

  useEffect(() => {
    setImages(
      importAll(require.context('./images', false, /\.(png|jpe?g|svg)$/))
    );
  }, []);

  function handleClickImage(index) {
    setModalVisible(true);
    setImageSelected(images[index]);
  }

  return (
    <>
      {modalVisible ? (
        <Modal setVisible={setModalVisible} image={imageSelected} />
      ) : (
        <></>
      )}
      <Container>
        <ContainerSubTitle>
          <div>
            <Title>NECÃO PINTOR</Title>
            <ColoredTitle>TRABALHOS</ColoredTitle>
          </div>
          <Image />
        </ContainerSubTitle>

        <GalleryContainer>
          {images.map((image, index) => {
            return (
              <CardImageJobs
                image={image}
                onClick={() => handleClickImage(index)}
              />
            );
          })}
        </GalleryContainer>
        <BaseBoard />
      </Container>
    </>
  );
}
