import { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import copyToClipboard from '../../utils/copyToClipboard';
import Description from '../Description';
import wppLogo from '../../assets/wpp-logo.jpg';
import { ModalContactContext } from '../../context/ModalContactContext';
// import { BlockScrollContext } from '../../context/BlockScrollContext';

const BackgroundContainer = styled.div`
  position: fixed;
  z-index: 2;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  /* overflow: auto; */
  background-color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
  /* overflow-x: hidden; */
`;

const ContactContainerFullScreen = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  /* overflow-x: hidden; */
`;

const ContactContainer = styled.div`
  position: fixed;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  /* display: flex; */
  z-index: 3;
  background-color: #fff;
  backdrop-filter: blur(15px);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 2rem;
  transition: all 300ms;
  /* will-change: contents; */

  @media screen and (max-width: 480px) {
    width: 80vw;
  }

  animation: fadeIn 300ms;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.3rem;
`;

const Title = styled.h1`
  color: #1e1e1e;
  font-weight: 700;
  letter-spacing: 0.07em;
  font-size: 2rem;
  line-height: 2.5rem;

  @media screen and (min-width: 1400px) {
    font-size: 3rem;
    line-height: 3.3rem;
  }

  @media screen and (max-width: 800px) {
    font-size: 2.3rem;
    line-height: 3.3rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 1.5rem;
    line-height: 3.3rem;
  }
`;

const PhoneNumber = styled.span`
  padding: 1rem;
  font-size: 1.5rem;
  color: ${({ copyText }) => (copyText ? '#d9d9d9' : '#1e1e1e')};
  background-color: ${({ copyText }) => (copyText ? '#1e1e1e' : 'none')};
  border: 1px solid #1e1e1e;
  border-radius: 5px;
  cursor: pointer;
`;

const MessageCopyText = styled.span`
  font-size: 1rem;
  color: #1e1e1e;
  text-align: center;
`;

const GoToWhatsApp = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #26d367;
  border-radius: 10px;
  text-decoration: none;

  span {
    padding-right: 1rem;
    text-decoration: none;
    color: #fff;
  }

  img {
    height: 5rem;
    border-radius: 10px;
  }
`;

export default function ContactModal() {
  const [copyText, setCopyText] = useState(false);
  const { visible, setVisible } = useContext(ModalContactContext);

  function handleVisible() {
    setCopyText(false);
    setVisible(false);
  }

  async function copyNumber() {
    copyToClipboard('17992113493');
    setCopyText(true);
  }

  function Elements() {
    return (
      <>
        <BackgroundContainer onClick={handleVisible} />
        <ContactContainerFullScreen>
          <ContactContainer>
            <ContentContainer>
              <Title>Vamos conversar!</Title>
              <Description bright={false}>
                Logo abaixo está meu contato, mas se preferir também tem um link
                para conversarmos direto via WhatsApp
              </Description>
              <PhoneNumber onClick={copyNumber} copyText={copyText}>
                17-99211-3493
              </PhoneNumber>
              {copyText ? (
                <MessageCopyText>Número copiado!</MessageCopyText>
              ) : (
                <></>
              )}
              <a
                aria-label="Chat on WhatsApp"
                href="https://wa.me/5517992113493?text=Olá,%20vim%20pelo%20seu%20site%20e%20gostaria%20de%20conversar%20sobre%20um%20trabalho%20:)"
                target="_blank"
              >
                <GoToWhatsApp>
                  <img alt="Chat on WhatsApp" src={wppLogo} />
                  <span>Clique para conversarmos via WhatsApp</span>
                </GoToWhatsApp>
              </a>
            </ContentContainer>
          </ContactContainer>
        </ContactContainerFullScreen>
      </>
    );
  }

  return <>{visible ? <Elements /> : <></>}</>;
}
