import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  /* width: 40%; */
  width: 30rem;
  min-height: 100%;

  @media screen and (max-width: 800px) {
    width: 100%;
    height: 30rem;
  }

  @media screen and (min-width: 1400px) {
    /* width: 100%; */
    width: 35rem;
    /* height: 30rem; */
  }
`;

export default function RightSideContainer({ children }) {
  return <Container>{children}</Container>;
}
