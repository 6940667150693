import styled from 'styled-components';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 20rem;
  height: 22rem;
  cursor: pointer;
  transition: all 200ms;

  @media screen and (min-width: 481px) {
    :hover {
      transform: scale(1.3);
    }
  }
`;

export const CardImage = styled.div`
  background-image: url(${({ img }) => img});
  background-position: center;
  /* background-size: contain; */
  background-size: auto 100%;
  background-repeat: no-repeat;
  height: 100%;
`;

// export const CardTextContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   background-color: #161616;
//   height: 3rem;
// `;

// export const CardText = styled.span`
//   color: #fff;
//   font-size: 1rem;
//   margin-left: 0.5rem;
// `;
