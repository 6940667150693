import styled from 'styled-components';

const Text = styled.h1`
  font-weight: 700;
  letter-spacing: 0.07em;
  font-size: 2rem;
  line-height: 2.5rem;
  background: linear-gradient(91.35deg, #19a67f 25.82%, #e59d24 84.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media screen and (max-width: 800px) {
    background: linear-gradient(20deg, #19a67f 20%, #e59d24 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 2.3rem;
    line-height: 3.3rem;
  }

  @media screen and (min-width: 1400px) {
    background: linear-gradient(20deg, #19a67f 20%, #e59d24 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 3rem;
    line-height: 3.3rem;
  }
`;

export default function ColoredTitle({ children }) {
  return <Text>{children}</Text>;
}
