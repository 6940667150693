import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  transform: ${({ isVisible }) => (isVisible ? 'none' : 'translateX(-10vw)')};
  /* transition-delay: 300ms; */
  transition: opacity 600ms ease-out, transform 600ms ease-out,
    visibility 600ms ease-out;
  will-change: opacity, transform, visibility;
`;

export default function LeftFadeInContainer({ children }) {
  const [isVisible, setVisible] = React.useState(true);
  const domRef = React.useRef();

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
    return () => {
      try {
        if (domRef.current) observer.unobserve(domRef.current);
      } catch (error) {}
    };
  }, []);

  return (
    <Container ref={domRef} isVisible={isVisible}>
      {children}
    </Container>
  );
}
