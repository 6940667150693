import '@fontsource/roboto';

const { createGlobalStyle } = require('styled-components');

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: "Roboto";
  }

  html, body, #root {
    height: 100%;
    min-height: 100%;
    max-width: 100%;
  }

  a {
    text-decoration: none;
  }
`;

export default GlobalStyle;
