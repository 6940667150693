import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import Introduction from './Components/Introduction';
import ChoseColor from './Components/ChoseColor';
import ColorPalette from './Components/ColorPalette';
import Qualifications from './Components/Qualifications';
import CallToAction from './Components/CallToAction';
import CallToSeeWork from './Components/CallToSeeWork';
import BaseBoard from './Components/BaseBoard';
import FadeInContainer from '../../components/FadeInContainer';
// import ContactModal from '../../components/ContactModal';
// import { ModalContactContext } from '../../context/ModalContactContext';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;

  @media screen and (max-width: 800px) {
    /* width: 100%; */
    /* min-width: 100%; */
    /* width: 100vw; */
  }
`;

export default function LandingPage() {
  // const [visible, setVisible] = useState(false);

  return (
    <Container>
      <Introduction />
      <FadeInContainer>
        <ColorPalette />
      </FadeInContainer>
      <Qualifications />
      <FadeInContainer animateOnce>
        <ChoseColor />
      </FadeInContainer>
      <CallToAction />
      <FadeInContainer>
        <CallToSeeWork />
      </FadeInContainer>
      <BaseBoard />
    </Container>
  );
}
