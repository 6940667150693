import { useContext } from 'react';
import styled from 'styled-components';
import image from '../../../../assets/circle-color.jpg';
import ActionButton from '../../../../components/ActionButton';
import ColoredTitle from '../../../../components/ColoredTitle';
import { ModalContactContext } from '../../../../context/ModalContactContext';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 40vh; */
  padding: 1rem 0;
  width: 100%;
  background-image: url(${({ img }) => img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0px 0px 250px 50px rgba(22, 22, 22, 0.7);

  @media screen and (max-width: 800px) {
    padding: 1rem 0;
    height: auto;
    box-shadow: inset 0px 0px 250px 50px rgba(22, 22, 22, 0.1);
  }
`;

const ContentContainer = styled.div`
  width: 30%;
  background-color: #fff;
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media screen and (max-width: 800px) {
    width: 90%;
  }
`;

export default function BaseBoard() {
  const { visible, setVisible } = useContext(ModalContactContext);
  return (
    <Container img={image}>
      <ContentContainer>
        <ColoredTitle>Será uma honra colorir sua residencia!</ColoredTitle>
        <ActionButton onClick={() => setVisible(!visible)}>
          ENTRAR EM CONTATO
        </ActionButton>
      </ContentContainer>
    </Container>
  );
}
