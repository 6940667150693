import styled from 'styled-components';

const Text = styled.h1`
  text-align: center;
  margin: 1.5rem;
  color: #fff;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 130%;
  letter-spacing: 0.2em;
`;

export default function Title({ children }) {
  return <Text>{children}</Text>;
}
