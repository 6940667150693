import styled from 'styled-components';

const Button = styled.button`
  width: 100%;
  padding: 1rem 1.5rem;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  border-radius: 0.4rem;
  cursor: pointer;

  -webkit-transition: opacity 0.2s ease-in;
  -moz-transition: opacity 0.2s ease-in;
  -o-transition: opacity 0.2s ease-in;
  -ms-transition: opacity 0.2s ease-in;
  transition: 0.2s ease-in;
  /* animation: fadeIn 5s; */

  :hover {
    /* --p: 100%; */
    box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
  }

  span {
    font-size: 1.3rem;
    color: #161616;
    line-height: 130%;
    letter-spacing: 0.085em;

    @media screen and (max-width: 800px) {
      font-size: 1.3rem;
    }

    @media screen and (min-width: 1400px) {
      font-size: 1.5rem;
      /* line-height: 3.3rem; */
    }
  }

  @media screen and (max-width: 800px) {
  }

  /* @keyframes fadeIn {
    0% {
      left: 100;
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  } */
`;

export default function ActionButton({ children, onClick = () => {} }) {
  return (
    <Button onClick={onClick}>
      <span>{children}</span>
    </Button>
  );
}
