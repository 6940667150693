import styled from 'styled-components';

import { useMediaQuery } from 'react-responsive';
import { useContext, useState } from 'react';
import background from '../../../../assets/color-fade.jpg';
import ActionButton from '../../../../components/ActionButton';
import ColoredTitle from '../../../../components/ColoredTitle';
import Description from '../../../../components/Description';
import ImageBanner from '../../../../components/ImageBanner';
import LeftSideContainer from '../../../../components/LeftSideContainer';
import Numbers from '../../../../components/Numbers';
import RightSideContainer from '../../../../components/RightSideContainer';
import Title from '../../../../components/Title';

import image from '../../../../assets/marcos-3.jpg';
// import ContactModal from '../../../../components/ContactModal';
import { ModalContactContext } from '../../../../context/ModalContactContext';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #000;
  background: url(${background});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  overflow: hidden;
  padding: 2rem;

  @media screen and (max-width: 800px) {
    height: 100%;
    box-shadow: inset 0px 0px 250px 50px rgba(0, 0, 0, 1);
  }
`;

const ContentContainer = styled.div`
  display: flex;
  gap: 1rem;
  height: 100%;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

const NumbersContainer = styled.div`
  display: flex;
  gap: 3rem;
`;

function Introduction() {
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 801px)' });
  const { visible, setVisible } = useContext(ModalContactContext);

  function MobileLayout() {
    if (isMobile)
      return (
        <>
          <ContentContainer>
            <LeftSideContainer>
              <ColoredTitle>VAMOS COLORIR SUA HISTÓRIA, JUNTOS</ColoredTitle>
              <ImageBanner image={image} />
              <Description>
                Com mais de 30 anos de profissão, após pintar milhares de
                residencias e comercios, consegui a experiencia necessária, para
                fazer um trabalho de qualidade, que produza no cliente um
                sentimento de satisfação por ter confiado em nossa prestação de
                serviço.
              </Description>
              <ActionButton onClick={() => setVisible(!visible)}>
                SIM! VAMOS COLORIR JUNTOS!
              </ActionButton>
              <NumbersContainer>
                <Numbers title="+1000" description="Construções pintadas" />
                <Numbers title="+30 Anos" description="De profissão" />
              </NumbersContainer>
            </LeftSideContainer>
          </ContentContainer>
        </>
      );
    return <></>;
  }

  function DesktopLayout() {
    if (isDesktop)
      return (
        <>
          {/* {visible ? <ContactModal setVisible={setVisible} /> : <></>} */}
          <ContentContainer>
            <LeftSideContainer>
              <ColoredTitle>VAMOS COLORIR SUA HISTÓRIA, JUNTOS</ColoredTitle>
              <Description>
                Com mais de 30 anos de profissão, após pintar milhares de
                residencias e comercios, consegui a experiencia necessária, para
                fazer um trabalho de qualidade, que produza no cliente um
                sentimento de satisfação por ter confiado em nossa prestação de
                serviço.
              </Description>
              <ActionButton onClick={() => setVisible(!visible)}>
                SIM! VAMOS COLORIR JUNTOS!
              </ActionButton>
              <NumbersContainer>
                <Numbers title="+1000" description="Construções pintadas" />
                <Numbers title="+30 Anos" description="De profissão" />
              </NumbersContainer>
            </LeftSideContainer>
            <RightSideContainer>
              <ImageBanner image={image} />
            </RightSideContainer>
          </ContentContainer>
        </>
      );
    return <></>;
  }

  return (
    <Container>
      <Title>NECÃO PINTOR</Title>
      <MobileLayout />
      <DesktopLayout />
    </Container>
  );
}

export default Introduction;
