import { useContext, useState } from 'react';
// import ContactModal from '../../../../components/ContactModal';
import { ModalContactContext } from '../../../../context/ModalContactContext';
import {
  ActionButton,
  Container,
  ContainerImg,
  ContainerText,
  Description,
  Title,
} from './styles';

export default function ColorPalette() {
  const { visible, setVisible } = useContext(ModalContactContext);
  return (
    <>
      <Container>
        <ContainerText>
          <Title>Dê a sua casa, a vida que ela precisa</Title>
          <Description>
            Atuamos principalmente na area de Monte Azul Paulista e região,
            porém já prestamos serviços em todo Brasil, desde MG até os litorais
            paulistas.
          </Description>
          <ActionButton onClick={() => setVisible(!visible)}>
            Vamos conversar
          </ActionButton>
        </ContainerText>
        <ContainerImg />
      </Container>
    </>
  );
}
