import { useContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ContactModal from '../components/ContactModal';
import ScrollToTop from '../components/ScrollToTop';
import { BlockScrollContext } from '../context/BlockScrollContext';
import { ModalContactContext } from '../context/ModalContactContext';
import GlobalStyle from '../styles/GlobalStyle';
import Jobs from './Jobs';
import LandingPage from './LadingPage';
import NotFound from './NotFound';

export default function App() {
  return (
    <>
      <GlobalStyle />
      <ContactModal />
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route index exact element={<LandingPage />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
