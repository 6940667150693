import styled from 'styled-components';
import backgroud from '../../../../assets/circle-color.jpg';
import QualificationText from '../../../../components/QualificationText';
import handShjakeImg from '../../../../assets/handshake.jpg';
import money from '../../../../assets/money.jpg';
import time from '../../../../assets/time.jpg';
import painting from '../../../../assets/painting.jpg';
// import RigthFadeInContainer from '../../../../components/RigthFadeInContainer';
import LeftFadeInContainer from '../../../../components/LeftFadeInContainer';
// import RightSideContainer from '../../../../components/RightSideContainer';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: url(${backgroud});
  background-position: center;
  background-size: cover;
  gap: 1rem;
  width: 100%;
  padding: 2rem 1rem;
  align-items: center;

  @media screen and (max-width: 800px) {
    padding: 2rem 0;
  }
`;

export default function Qualifications() {
  return (
    <Container>
      <LeftFadeInContainer>
        <QualificationText
          title="Tenha uma equipe de confiança e respeito em sua residencia"
          description="Você não precisara se preocupar e nem temer o trabalhador que estará em sua casa"
          descriptionBold=", pois minha equipe é sinônimo de confiança e respeito."
          buttonText="Veja nosso trabalho"
          img={handShjakeImg}
        />
      </LeftFadeInContainer>

      <LeftFadeInContainer>
        <QualificationText
          title="Receba um orçamento justo, sem preços abusivos"
          description="Um dos medos dos consumidores, é justamente na hora de receber o orçamento do prestador de serviço"
          descriptionBold=", conosco você pode ficar tranquilo(a) que receberá um orçamento justo!"
          img={money}
        />
      </LeftFadeInContainer>

      <LeftFadeInContainer>
        <QualificationText
          title="Otimize o tempo de trabalho, com uma equipe dinâmica"
          description="Tempo sem dúvida é o ativo mais valioso para todos e um tempo mal aproveitado não volta atrás,"
          descriptionBold=", devido a isso, nossa equipe sempre busca otimizar o tempo de trabalho, afim de entregar o resultado final o quanto antes"
          img={time}
        />
      </LeftFadeInContainer>
      <LeftFadeInContainer>
        <QualificationText
          title="A pintura feita pelos profissionais corretos, duram anos (Qualidade)"
          description="Quando um profissional inesperiente faz uma pintura, geralmente ela precisa de retoque muito rápido, oque acaba sendo prejudicial a longo prazo."
          descriptionBold="Nossa equipe é capaz de selecionar as melhores tintas para cada ocasião, reduzindo riscos e custos e aumentando a qualidade do trabalho"
          buttonText="Veja nosso trabalho"
          img={painting}
        />
      </LeftFadeInContainer>
    </Container>
  );
}
