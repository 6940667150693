import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: space-between;
  width: 30%;
  height: 90%;
  gap: 2rem;

  @media screen and (max-width: 800px) {
    width: 100%;
  }

  /* animation: fadeIn 2s;
  @keyframes fadeIn {
    0% {
      transform: translateX(-100px);
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  } */
`;

export default function LeftSideContainer({ children }) {
  return <Container>{children}</Container>;
}
