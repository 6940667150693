import {
  CardContainer,
  CardImage,
  // CardText,
  // CardTextContainer,
} from './styles';

export default function CardImageJobs({ image, onClick }) {
  return (
    <CardContainer onClick={onClick}>
      <CardImage img={image} />
      {/* <CardTextContainer>
        <CardText>{text}</CardText>
      </CardTextContainer> */}
    </CardContainer>
  );
}
