import styled from 'styled-components';

const BackgroundContainer = styled.div`
  position: fixed;
  z-index: 2;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: rgba(0, 0, 0, 1);
  cursor: pointer;
`;

const ContainerFullScreen = styled.div`
  position: absolute;
  /* z-index: 1; */
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  /* width: 90vw; */
  width: 100%;
  height: 100%;
  /* height: 100vh; */
  overflow: hidden;
`;

// const Container = styled.div`
//   position: fixed;
//   z-index: 3;
//   background-color: #fff;
//   backdrop-filter: blur(15px);
//   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
//   border-radius: 10px;
//   /* padding: 1rem; */
//   transition: all 300ms;
//   width: 0;
//   height: 0;

//   @media screen and (max-width: 480px) {
//     /* width: 80vw; */
//   }

//   animation: fadeIn 300ms;
//   @keyframes fadeIn {
//     0% {
//       opacity: 0;
//     }
//     100% {
//       opacity: 1;
//     }
//   }
// `;

const Image = styled.div`
  position: fixed;
  margin: 0 auto;
  z-index: 3;
  background-image: url(${({ image }) => image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #1c1c1c;

  @media screen and (min-width: 481px) {
    width: 80vw;
    min-height: 35rem;
  }

  @media screen and (max-width: 480px) {
    width: 100vw;
    min-height: 20rem;
  }

  animation: fadeIn 300ms;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export default function Modal({ image, setVisible }) {
  function handleVisible() {
    setVisible(false);
  }

  return (
    <>
      <BackgroundContainer onClick={handleVisible} />
      <ContainerFullScreen>
        {/* <Container> */}
        <Image image={image} />
        {/* </Container> */}
      </ContainerFullScreen>
    </>
  );
}
