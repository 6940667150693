import { useContext, useState } from 'react';
import styled from 'styled-components';
import texture from '../../../../assets/texture.jpg';
import { BlockScrollContext } from '../../../../context/BlockScrollContext';
import ModalChooseColor from './Components/ModalChooseColor';

const Container = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 2rem;
  @media screen and (max-width: 800px) {
    height: auto;
    flex-direction: column;
    align-items: center;
  }
`;

const ContainerColor = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 25%;

  @media screen and (max-width: 800px) {
    width: 90%;
  }
`;

const ContainerImg = styled.div`
  padding: 1rem;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 40%;
  background-image: url(${texture});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: ${({ color }) => `${color}E5`};
  background-blend-mode: multiply;

  @media screen and (max-width: 800px) {
    width: 90%;
    height: 25rem;
  }
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 130.52%;
  letter-spacing: -0.04em;
  color: #57606a;

  @media screen and (max-width: 800px) {
    font-size: 1.7rem;
  }
`;

const Description = styled.span`
  font-weight: 100;
  font-size: 1rem;
  line-height: 130.52%;
  letter-spacing: -0.04em;
  color: #57606a;

  @media screen and (max-width: 800px) {
    font-size: 1.3rem;
  }

  @media screen and (min-width: 1400px) {
    font-size: 1.3rem;
  }
`;

const Color = styled.div`
  height: 12rem;
  border-radius: 10px 10px 0 0;
  background-color: ${({ color }) => color};
  cursor: pointer;

  @media screen and (max-width: 800px) {
    height: 4rem;
  }
`;

const Button = styled.button`
  background-color: white;
  border: 1px solid #d9d9d9;
  border-radius: 0px 0px 10px 10px;
  font-weight: 400;
  font-size: 1rem;
  line-height: 130.52%;
  letter-spacing: -0.04em;
  color: #161616;
  text-align: center;
  width: 100%;
  padding: 1rem 0;
  cursor: pointer;

  @media screen and (max-width: 800px) {
    font-size: 1.3rem;
  }
`;

export default function ChoseColor() {
  const [color, setColor] = useState('#003b75');
  const [nameColor, setNameColor] = useState('SELECIONE A COR');
  const [visibleModal, setVisibleModal] = useState(false);
  const { setBlock } = useContext(BlockScrollContext);

  function handleButton() {
    setVisibleModal(true);
    setBlock(true);
  }

  return (
    <>
      {visibleModal ? (
        <ModalChooseColor
          setVisibleModal={setVisibleModal}
          setColor={setColor}
          setNameColor={setNameColor}
        />
      ) : (
        <></>
      )}
      <Container>
        <ContainerColor>
          <Title>
            Quer uma ajuda para escolher uma cor nova para sua residencia?
          </Title>
          <Description>
            Cada cor tem um significado e atribuido a ela os seres humos
            conseguem sentir diferentes sentimentos. Separei algumas cores bem
            bacanas para lhe ajudar
          </Description>
          <div>
            <Color color={color} />
            <Button onClick={handleButton}>{nameColor}</Button>
          </div>
        </ContainerColor>
        <ContainerImg color={color} />
      </Container>
    </>
  );
}
