import styled from 'styled-components';

const Title = styled.span`
  font-size: 1.7rem;
  line-height: 1.5rem;
  letter-spacing: 0.1em;
  color: #ffffff;
  margin-bottom: 0.5rem;
`;
const Description = styled.span`
  font-size: 1rem;
  line-height: 1rem;
  color: #ffffff;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export default function Numbers({ title, description }) {
  return (
    <Container>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Container>
  );
}
