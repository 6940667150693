import styled from 'styled-components';
import palette from '../../../../assets/color-palette.jpg';

export const Container = styled.div`
  display: flex;
  width: 50%;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 800px) {
    width: 90%;
    /* margin: 0 2rem; */
    flex-direction: column;
  }

  /* :hover {
    animation: fadeIn 2s;
  }
  @keyframes fadeIn {
    0% {
      transform: translateX(-100px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  } */
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 50%;
  gap: 1rem;

  background: #ffffff;
  border: 1px solid #d9d9d9;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 10px 0 0 10px;

  @media screen and (max-width: 800px) {
    width: 100%;
    border-radius: 10px 10px 0 0;
    /* flex-direction: column; */
  }
`;

export const Title = styled.h1`
  font: 700;
  color: #57606a;
  font-size: 1.5rem;
  line-height: 130%;
  letter-spacing: -0.04em;
`;

export const Description = styled.span`
  font-style: normal;
  font-weight: 100;
  font-size: 1rem;
  line-height: 130.52%;
  letter-spacing: -0.04em;
  color: #57606a;

  @media screen and (max-width: 800px) {
    font-size: 1.2rem;
  }

  @media screen and (min-width: 1400px) {
    font-size: 1.3rem;
  }
`;

export const ActionButton = styled.button`
  color: #161616;
  padding: 0.5rem 1.2rem;
  border: solid 1px #d9d9d9;
  border-radius: 5px;
  line-height: 130.52%;
  font-size: 1rem;
  letter-spacing: -0.04em;
  background-color: #fff;
  cursor: pointer;
  width: 70%;

  @media screen and (max-width: 800px) {
    padding: 1rem;
    font-size: 1.3rem;
  }

  @media screen and (min-width: 1400px) {
    font-size: 1.3rem;
    padding: 1rem;
  }
`;

export const ContainerImg = styled.div`
  background: url(${palette});
  background-position: center;
  background-size: cover;
  /* border: 1px solid #d9d9d9; */
  border-radius: 0px 10px 10px 0px;
  width: 50%;
  /* height: 100%; */

  @media screen and (max-width: 800px) {
    width: 100%;
    border-radius: 0px 0px 10px 10px;
    height: 10rem;
  }
`;
