import styled from 'styled-components';

import { useContext } from 'react';
import background from '../../../../assets/color-fade.jpg';
import ActionButton from '../../../../components/ActionButton';
import ColoredTitle from '../../../../components/ColoredTitle';
import Description from '../../../../components/Description';
import ImageBanner from '../../../../components/ImageBanner';
import LeftSideContainer from '../../../../components/LeftSideContainer';
import RightSideContainer from '../../../../components/RightSideContainer';

// import image from '../../../../assets/equipe-pintando.jpg';
import image from '../../../../assets/marcos.jpg';
// import RigthFadeInContainer from '../../../../components/RigthFadeInContainer';
import FadeInContainer from '../../../../components/FadeInContainer';
// import ContactModal from '../../../../components/ContactModal';
import { ModalContactContext } from '../../../../context/ModalContactContext';

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  /* height: 90vh; */
  padding: 2rem 0;
  background-color: #000;
  background: url(${background});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media screen and (max-width: 800px) {
    padding: 2rem;
    height: auto;
    flex-direction: column;
    box-shadow: inset 0px 0px 250px 50px rgba(0, 0, 0, 1);
  }
`;

function CallToAction() {
  const { visible, setVisible } = useContext(ModalContactContext);
  return (
    <>
      {/* {visible ? <ContactModal setVisible={setVisible} /> : <></>} */}
      <Container>
        <LeftSideContainer>
          <ColoredTitle>
            QUER UM TRABALHO DE QUALIDADE A UM PREÇO JUSTO?
          </ColoredTitle>
          <FadeInContainer>
            <ActionButton onClick={() => setVisible(!visible)}>
              SIM! EU QUERO!
            </ActionButton>
          </FadeInContainer>
          <Description>
            Depois de realizar milhares de trabalhos na area da pintura,
            conheçemos a maioria das melhores técnicas práticadas no mercado,
            dentre elas está:
          </Description>
          <Description>
            <li>Gráfiato</li>
            <li>Texturas especiais</li>
            <li>Marmorizamento</li>
            <li>Pintura anti-infiltração</li>
          </Description>
        </LeftSideContainer>
        <RightSideContainer>
          <ImageBanner image={image} />
        </RightSideContainer>
      </Container>
    </>
  );
}

export default CallToAction;
