import { useNavigate } from 'react-router-dom';
import ActionButton from '../../../../components/ActionButton';
import ColoredTitle from '../../../../components/ColoredTitle';
import Description from '../../../../components/Description';
import { Container, ContainerImg, ContainerText } from './styles';

export default function CallToSeeWork() {
  const navigate = useNavigate();

  function handleButton() {
    navigate('/jobs');
  }

  return (
    <Container>
      <ContainerText>
        <ColoredTitle>DESEJA VER NOSSO TRABALHO?</ColoredTitle>
        <Description>
          Transparencia com o cliente é algo que prezamos muito, então aqui você
          poderá ver alguns de nossos trabalhos, para ter certeza que nos
          adequamos as suas necessidades
        </Description>
        <ActionButton onClick={handleButton}>IR PARA TRABALHOS</ActionButton>
      </ContainerText>
      <ContainerImg />
    </Container>
  );
}
