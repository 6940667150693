import { useContext } from 'react';
import styled from 'styled-components';
// import colors from '../../../../../../colors-mini.json';
// import colors from '../../../../../../colors-mini.json';
import colors from '../../../../../../colors-mini1.json';

import { BlockScrollContext } from '../../../../../../context/BlockScrollContext';

const BackgroundContainer = styled.div`
  position: fixed;
  z-index: 2;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  /* background-color: rgba(0, 0, 0, 0.25); */
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.25);
  /* overscroll-behavior: contain; */
`;

const ContainerFullScreen = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  height: 35rem;
  /* width: 25rem; */
  position: fixed;
  z-index: 3;
  background-color: #fff;
  backdrop-filter: blur(15px);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 1rem;
  transition: all 300ms;

  @media screen and (max-width: 480px) {
    width: 80vw;
  }

  animation: fadeIn 300ms;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 1rem;
  cursor: pointer;
  padding: 1rem;
  border-radius: 10px;

  overflow-y: scroll;
`;

const ColorContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;

  :hover {
    background-color: rgba(0, 0, 0, 0.25);
  }
`;

const Color = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 10px;
  border: none;
  background-color: ${({ color }) => color};
`;

const Text = styled.span`
  font-size: 1rem;
  color: #1e1e1e;
`;

export default function ModalChooseColor({
  setVisibleModal,
  setColor,
  setNameColor,
}) {
  const { setBlock } = useContext(BlockScrollContext);

  function handleCloseModal() {
    setVisibleModal(false);
    setBlock(false);
  }

  function handleColor(color) {
    setColor(color.hex);
    setNameColor(color.name);
    setVisibleModal(false);
    setBlock(false);
  }

  function RenderColors() {
    return colors.map((color) => {
      return (
        <ColorContainer onClick={() => handleColor(color)}>
          <Color color={color.hex} />
          <Text>{color.name}</Text>
        </ColorContainer>
      );
    });
  }

  return (
    <>
      <BackgroundContainer onClick={handleCloseModal} />
      <ContainerFullScreen>
        <Container>
          <ContentContainer>
            <RenderColors />
          </ContentContainer>
        </Container>
      </ContainerFullScreen>
    </>
  );
}
