import styled from 'styled-components';

const Text = styled.span`
  /* margin-top: 1rem; */
  color: ${({ bright }) => (bright ? '#f9f9f9' : '#57606A')};
  font-weight: 400;
  font-size: 1rem;
  line-height: 130%;

  @media screen and (max-width: 800px) {
    font-size: 1.3rem;
    /* line-height: 3.3rem; */
  }

  @media screen and (min-width: 1400px) {
    font-size: 1.5rem;
    /* line-height: 3.3rem; */
  }
`;

export default function Description({ children, bright = true }) {
  return <Text bright={bright}>{children}</Text>;
}
