import styled from 'styled-components';

const Image = styled.div`
  background: url(${({ image }) => image});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  border: 1.5rem solid rgba(255, 255, 255, 0.9);
  border-radius: 0.6rem;
  min-width: 70%;
  height: 90%;

  @media screen and (max-width: 800px) {
    height: 22rem;
    width: 100%;
    /* min-height: 100%; */
    /* min-width: 100%; */
    /* min-height: 100%; */
  }

  @media screen and (min-width: 1400px) {
    /* height: 22rem; */
    /* height: 100%; */
    width: 80%;
  }
`;

export default function ImageBanner({ image }) {
  return <Image image={image} />;
}
